import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
// import PriceRange from "../../components/Filter/PriceRange";
import { FilterContext } from "../../context/FilterContext";
// import SearchSelectList from "../../components/Filter/SearchSelectList";
import PageProductsListing from "../../components/PageProductsListing";
import { useSelector } from "react-redux";
import PageNotFound from "../../components/PageNotFound";
import { Helmet } from "react-helmet-async";

// check if catgory exists, if not page not found

const ProductCategory = () => {
  const { category } = useParams();

  const { categories } = useSelector((state) => state.data);

  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);

  const search = urlParams.get("q");

  const {
    minPrice,
    maxPrice,
    // setMinPrice,
    // setMaxPrice,
    filterbrands,
    // setBrands,
    filtercolors,
    // setColors,
  } = useContext(FilterContext);

  // check if the categories in url exist
  const [checkedCategLevels, setcheckedCategLevels] = useState([]);

  const [pageNotFound, setPageNotFound] = useState(false);

  useEffect(() => {
    function getCategs(params) {
      if ((categories || []).length >= 1) {
        let p = [];

        for (const i in params) {
          const categ = categories.find((c) => {
            return c.name.toLowerCase() === params[i].toLowerCase();
          });
          if (categ) {
            p.push(categ);
          } else {
            // if categ is undefined, i.e, not found, display page not found
            setPageNotFound(true);
            break;
          }
        }
        setcheckedCategLevels(p);
        stillComputing.current = false;
      }
    }

    getCategs(category.split("-"));
    // eslint-disable-next-line
  }, [category, categories]);

  let stillComputing = useRef(true);

  return (
    <>
      {pageNotFound ? (
        <PageNotFound />
      ) : (
        <>
          <Helmet>
            <title>{category}</title>
            <meta
              name="description"
              content={`Browse through Oasis Auto Parts ${category} Catalog.`}
            />
            <link rel="canonical" href={`/product-category/${category}`} />
          </Helmet>
          <div className="categoryPage">
            <div className="followLinks">
              <Link to={"/"}>Home </Link>
              {checkedCategLevels.map((c, index) => {
                return (
                  <span key={index}>
                    / <Link to={`/product-category/${c.name}/`}>{c.name} </Link>
                  </span>
                );
              })}
            </div>

            <Container className="custom_container">
              <Row>
                <Col sm={4} className="pageFilters">
                  <h5>CATEGORY</h5>
                  <div className="categLvlLinks">
                    {checkedCategLevels.map((c, index) => {
                      return (
                        <Link
                          to={`/product-category/${c.name}/`}
                          key={index}
                          className="topLvlCategLinks"
                          style={{
                            color:
                              index === checkedCategLevels.length - 1
                                ? "rgb(243 200 0)"
                                : "gray",
                            fontWeight:
                              index === checkedCategLevels.length - 1
                                ? "600"
                                : "300",
                          }}
                        >
                          {c.name}
                        </Link>
                      );
                    })}
                    <div className="sub_categs_div">
                      {checkedCategLevels[checkedCategLevels.length - 1] &&
                        checkedCategLevels[
                          checkedCategLevels.length - 1
                        ].sub_categories.map((sub_categ, index) => {
                          return (
                            <Link
                              to={`/product-category/${category}-${sub_categ.name}/`}
                              key={index}
                              className="subLvlCategLinks"
                            >
                              {sub_categ.name}
                            </Link>
                          );
                        })}
                    </div>
                  </div>
                  <hr />
                  {/* <h5>PRICE</h5>
                <PriceRange
                  minPrice={minPrice}
                  maxPrice={maxPrice}
                  setUpMax={setMaxPrice}
                  setUpMin={setMinPrice}
                />
                <hr />
                <h5>MAKE/BRAND</h5>
                <SearchSelectList
                  items={brands || []}
                  keyName="name"
                  setSelected={setBrands}
                  selectedItems={filterbrands}
                />
                <hr />
                <h5>COLOR</h5>
                <SearchSelectList
                  items={colors || []}
                  keyName={"value"}
                  setSelected={setColors}
                  selectedItems={filtercolors}
                /> */}
                </Col>
                <Col sm={8} className="pageProductsListing">
                  <PageProductsListing
                    name={
                      checkedCategLevels.length >= 1
                        ? checkedCategLevels[checkedCategLevels.length - 1][
                            "name"
                          ]
                        : ""
                    }
                    categList={checkedCategLevels}
                    brand={filterbrands}
                    color={filtercolors}
                    minPrice={minPrice}
                    maxPrice={maxPrice}
                    search={search}
                    stillComputing={stillComputing.current}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
    </>
  );
};

export default ProductCategory;
