import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./user/UserSlice";
import cartReducer from "./cart/CartSlice";
import dataReducer from "./data/DataSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    cart: cartReducer,
    data: dataReducer,
  },
});

export default store;
