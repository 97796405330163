import React from "react";
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const CategoriesCarousel = () => {
  const { categories } = useSelector((state) => state.data);

  // const responsive = {
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 7,
  //   },
  //   tablet: {
  //     breakpoint: { max: 1024, min: 464 },
  //     items: 5,
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 3,
  //   },
  // };

  return (
    // removed carousel and added the categories_carousel_div classname
    <div className="categories_carousel_div" role="menubar">
      {/* <Carousel
        responsive={responsive}
        arrows={false}
        draggable={true}
        showDots={false}
        infinite={true}
        autoPlay={false}
        autoPlaySpeed={2000}
        containerClass="carousel-container"
        itemClass="carousel-item-padding-40-px"
      > */}
      {(categories || [])
        .filter((x) => x.top_level)
        .map((e, index) => {
          return (
            <div
              className="dropdown custom-dropdown"
              key={index}
              itemScope
              itemType="https://schema.org/category"
            >
              <Link to={`/product-category/${e.name}/`}>
                <div key={index} className="carousel-category-item">
                  <img
                    itemProp="image"
                    src={`${process.env.REACT_APP_API_URL}${e.image}`}
                    alt=""
                  />
                  <h5 itemProp="name">{e.name}</h5>
                </div>
              </Link>
              {(e?.sub_categories ?? []).length > 0 && (
                <div
                  data-bs-popper="static"
                  className="dropdown-menu scroll-container"
                >
                  {(e?.sub_categories ?? []).map((item, index) => {
                    return (
                      <Link
                        key={index}
                        data-rr-ui-dropdown-item
                        className="dropdown-item"
                        to={`/product-category/${e.name}-${item?.name}/`}
                      >
                        {item?.name}
                      </Link>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      {/* </Carousel> */}
    </div>
  );
};

export default CategoriesCarousel;
