import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import LogIn from "./pages/authentication/Log-In/LogIn";
import SignUp from "./pages/authentication/signup/SignUp";
import ScrollToTop from "./ScrollToTop";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import ProductCategory from "./pages/category/ProductCategory";
import PageNotFound from "./components/PageNotFound";
import FilterContextProvider from "./context/FilterContext";
import Catalog from "./pages/catalog/Catalog";
import ProductPage from "./pages/product/ProductPage";
import CartPage from "./pages/cart/CartPage";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { checkIsAuthenticated } from "./store/user/UserActions";
import { fetchCart } from "./store/cart/CartActions";
import Activate from "./pages/authentication/signup/Activate";
import ResetPassword from "./pages/authentication/Log-In/ResetPassword";
import ResetPasswordConfirm from "./pages/authentication/Log-In/ResetPasswordConfirm";
import AdminLogin from "./pages/admin/forms/AdminLoginForm";
import AdminPage from "./pages/admin/AdminPage";
import AdminListProducts from "./pages/admin/AdminListProducts";
import ProductForm from "./pages/admin/forms/ProductForm";
import AdminListCategories from "./pages/admin/AdminListCategories";
import CategoryForm from "./pages/admin/forms/CategoryForm";
import { fetchData } from "./store/data/DataAction";
import AdminListFeaturedProducts from "./pages/admin/AdminListFeaturedProducts";
import FloatingContact from "./components/FloatingContact";
import AdminListSubCategories from "./pages/admin/AdminListSubCategories";
import Profile from "./pages/profile/Profile";
import AboutUs from "./pages/AboutUs/AboutUs";
import CheckOut from "./pages/checkout/CheckOut";
import MyOrders from "./pages/orders/MyOrders";
import AdminListOrders from "./pages/admin/AdminListOrders";
import OrderForm from "./pages/admin/forms/OrderForm";

function App() {
  const { is_staff } = useSelector((state) => state.user?.user || false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchData());

    dispatch(checkIsAuthenticated()).then((_) => {
      dispatch(fetchCart());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <FilterContextProvider>
        <Router>
          <ScrollToTop />
          <NavBar />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/login" element={<LogIn />} />
            <Route exact path="/signup" element={<SignUp />} />
            <Route exact path="/activate/:uid/:token" element={<Activate />} />
            <Route exact path="/reset_password" element={<ResetPassword />} />
            <Route
              exact
              path="/password/reset/confirm/:uid/:token"
              element={<ResetPasswordConfirm />}
            />
            <Route
              exact
              path="/product-category/:category/"
              element={<ProductCategory />}
            />
            <Route exact path="/catalog/" element={<Catalog />} />
            <Route exact path="/shop/:productname/" element={<ProductPage />} />
            <Route exact path="/cart/" element={<CartPage />} />
            <Route exact path="/orders/" element={<MyOrders />} />
            <Route exact path="/checkout/" element={<CheckOut />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/about-us" element={<AboutUs />} />
            <Route exact path="/admin/login" element={<AdminLogin />} />
            {is_staff && (
              <>
                <Route exact path="/admin/" element={<AdminPage />} />
                <Route
                  exact
                  path="/admin/products/"
                  element={<AdminListProducts />}
                />
                <Route
                  exact
                  path="/admin/products/add/"
                  element={<ProductForm />}
                />
                <Route
                  exact
                  path="/admin/products/change/:pk/"
                  element={<ProductForm />}
                />

                <Route
                  exact
                  path="/admin/categories/"
                  element={<AdminListCategories />}
                />
                <Route
                  exact
                  path="/admin/categories/add/"
                  element={<CategoryForm />}
                />
                <Route
                  exact
                  path="/admin/categories/change/:pk"
                  element={<CategoryForm />}
                />
                <Route
                  exact
                  path="/admin/popular-products/"
                  element={<AdminListFeaturedProducts />}
                />

                <Route
                  exact
                  path="/admin/sub-categories/"
                  element={<AdminListSubCategories />}
                />
                <Route
                  exact
                  path="/admin/sub-categories/add/"
                  element={<CategoryForm />}
                />
                <Route
                  exact
                  path="/admin/sub-categories/change/:pk"
                  element={<CategoryForm />}
                />
                <Route
                  exact
                  path="/admin/orders/"
                  element={<AdminListOrders />}
                />

                <Route
                  exact
                  path="/admin/orders/change/:pk"
                  element={<OrderForm />}
                />
              </>
            )}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
          <Footer />
          <FloatingContact />
        </Router>
      </FilterContextProvider>
    </div>
  );
}

export default App;
