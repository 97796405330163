import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  ArrowRepeat,
  Cart,
  CartCheck,
  Heart,
  Whatsapp,
} from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { alterCart } from "../store/cart/CartActions";
import ProductQuickView from "./ProductQuickView";
import noImage from "../assets/images/no-image.png";

const ProductContainer = ({ product, showPercentageOff }) => {
  const dispatch = useDispatch();

  const [percentOff, setPercentOff] = useState(0);

  const [showQuickView, setShowQuickView] = useState(false);

  const { loading, shopping_cart } = useSelector((state) => state.cart);

  useEffect(() => {
    function calculatePercentOff() {
      let perc = 0;
      if (product) {
        if (product.price && product.regular_price) {
          perc =
            ((product.regular_price - product.price) / product.regular_price) *
            100;
        }
      }

      return perc;
    }
    setPercentOff(calculatePercentOff());
  }, [product]);

  return (
    <>
      <div
        className="product_container"
        itemScope
        itemType="https://schema.org/Product"
      >
        <div className="product_container_content">
          <div className="product_left_actions">
            {showPercentageOff && percentOff > 0 ? (
              <>
                <div className="percantage_off">
                  {" "}
                  -{percentOff.toFixed(1)}%{" "}
                </div>
                <br />
              </>
            ) : (
              <></>
            )}

            {/* <Link
              // to={`https://wa.me/254795321968/?text=${encodeURIComponent(
              //   "Hello, I would like to purchase \n\nProduct :" +
              //     `${product.name}` +
              //     "\nPrice : Ksh " +
              //     `${product.price}` +
              //     `\nURL : https://www.oasisautoparts.co.ke/shop/${product.name}-${product.id}` +
              //     "/ \n\nThank you."
              // )}`}
              to={`https://wa.me/254795321968/?text=${encodeURIComponent(
                "Hello, I would like to purchase \n\nProduct :" +
                  `${product.name}` +
                  "\nPrice : Ksh " +
                  `${product.price}` +
                  `\nURL : ${encodeURI(
                    `https://www.oasisautoparts.co.ke/shop/${product.name}-${product.id}`
                  )}` +
                  "/\n\nThank you."
              )}`}
              target="_blank"
            >
              {/* to={`https://wa.me/254795321968/?text=${encodeURIComponent(
              "Hello, I would like to purchase \n\nProduct :" +
                `${product.name}` +
                "\nPrice : Ksh " +
                `${product.price}` +
                `\nURL : ${encodeURI(
                  `https://www.oasisautoparts.co.ke/shop/${product.name}-${product.id}`
                )}` +
                "/\n\nThank you."
            )}`} 
              <Whatsapp size={28} color="green" type="icon" />{" "}
            </Link> 
            <br />
            <Button
              onClick={() => {
                setShowQuickView(true);
              }}
            >
              <ArrowRepeat size={28} color="black" />
            </Button> */}
          </div>
          <div className="product_extended_actions">
            <Link>
              <Heart size={25} />
            </Link>
          </div>
          <Link to={`/shop/${product.name}-${product.id}/`}>
            {/* <ElementVisibilityChecker
            loadingElement={
              <div className="loading-card loading-card-image placeholder_image">
                <img alt="" />
              </div>
            }
            displayElement={
              <img
                className="prod_image"
                src={`${process.env.REACT_APP_API_URL}\\${product.images[0]?.src}`}
                alt=""
              />
            }
          /> */}

            <img
              className="prod_image"
              itemProp="image"
              src={
                product.images[0]?.src
                  ? `${process.env.REACT_APP_API_URL}` +
                    `${product.images[0]?.src}`
                  : `${noImage}`
              }
              alt={product.name}
            />
          </Link>
          <div className="prod_name_div">
            <p itemProp="name">
              <Link to={`/shop/${product.name}-${product.id}/`}>
                {product.name}
              </Link>
            </p>
          </div>
          {/* <StarRating itemProp="aggregateRating" itemScope itemType="https://schema.org/AggregateRating" value={product.average_rating} /> */}

          <h5 itemProp="offers" itemScope itemType="https://schema.org/Offer">
            <a
              itemprop="availability"
              href="https://schema.org/InStock"
              style={{ display: "none" }}
            ></a>
            <a
              itemprop="priceValidUntil"
              content="2030-12-30"
              style={{ display: "none" }}
            ></a>
            <span itemProp="priceCurrency" content="KES">
              Ksh.
            </span>{" "}
            <span itemProp="price" content={product.price}>
              {product.price}
            </span>
          </h5>
          <p>
            {percentOff > 0 ? (
              <span className="off_price">Ksh. {product.regular_price}</span>
            ) : (
              <span>
                <br />{" "}
              </span>
            )}
          </p>
          <span itemprop="description" style={{ display: "none" }}>
            {product?.description}
          </span>
        </div>
        <div className="product_container_btn">
          <Row>
            <Col>
              {(shopping_cart || []).some((c) => c.prod_id === product.id) ? (
                <Button disabled>
                  {" "}
                  <CartCheck size={25} color="grey" />
                </Button>
              ) : (
                <Button
                  onClick={() =>
                    dispatch(
                      alterCart({
                        prod_id: product.id,
                        quantity: 1,
                        meta_data: [],
                      })
                    )
                  }
                  disabled={loading}
                >
                  <Cart size={28} color="black" />
                </Button>
              )}
            </Col>
            <Col>
              <Link
                to={`https://wa.me/254795321968/?text=${encodeURIComponent(
                  "Hello, I would like to purchase \n\nProduct :" +
                    `${product.name}` +
                    "\nPrice : Ksh " +
                    `${product.price}` +
                    `\nURL : ${encodeURI(
                      `https://www.oasisautoparts.co.ke/shop/${product.name}-${product.id}`
                    )}` +
                    "/\n\nThank you."
                )}`}
                target="_blank"
              >
                <Whatsapp size={28} color="green" type="icon" />{" "}
              </Link>
            </Col>
            <Col>
              <Button
                onClick={() => {
                  setShowQuickView(true);
                }}
              >
                <ArrowRepeat size={28} color="black" />
              </Button>
            </Col>
          </Row>
          {/* {(shopping_cart || []).some((c) => c.prod_id === product.id) ? (
            <Button disabled>ADDED</Button>
          ) : (
            <Button
              onClick={() =>
                dispatch(
                  alterCart({
                    prod_id: product.id,
                    quantity: 1,
                    meta_data: [],
                  })
                )
              }
              disabled={loading}
            >
              Add To Cart
            </Button>
          )} */}
        </div>
      </div>
      <ProductQuickView
        product={product}
        show={showQuickView}
        setShow={setShowQuickView}
      />
    </>
  );
};

export default ProductContainer;
