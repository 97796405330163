import React, { useEffect } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import CartContainer from "./CartContainer";
import { useDispatch, useSelector } from "react-redux";
import { emptyCart, tallyCart } from "../../store/cart/CartActions";

const CartPage = () => {
  const { shopping_cart, sub_total } = useSelector((state) => state.cart);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(tallyCart());
    // eslint-disable-next-line
  }, [shopping_cart]);

  return (
    <div className="cartPage">
      <div className="followLinks">
        <Link to={"/"}>Home </Link>/ <Link to={`/cart/`}>Cart </Link>
      </div>

      <br />
      <h2>Shopping Cart</h2>

      <br />

      <Container className="custom_container">
        <Row>
          <Col sm={8} className="shopping_cart_list">
            <Table>
              <thead style={{ textAlign: "left" }}>
                <tr>
                  <th>Product</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Sub Total</th>
                </tr>
              </thead>
              <tbody>
                {(shopping_cart || []).map((element, index) => {
                  return (
                    <CartContainer
                      key={index}
                      cart_item={element}
                      alt_display={false}
                    />
                  );
                })}
              </tbody>
            </Table>
            <div className="cart_min_display">
              {(shopping_cart || []).map((element, index) => {
                return (
                  <CartContainer
                    key={index}
                    cart_item={element}
                    alt_display={true}
                  />
                );
              })}
            </div>
            <Button
              className="empty_cart"
              onClick={() => dispatch(emptyCart())}
              disabled={(shopping_cart || []).length < 1}
            >
              EMPTY CART
            </Button>
          </Col>

          <Col sm={4} className="cart_checkout">
            <h4>Cart Totals</h4>
            <p>
              <span style={{ color: "gray" }}>Sub Total</span>{" "}
              <span style={{ color: "blue", fontSize: "17px" }}>
                Ksh. {sub_total}
              </span>
            </p>
            <hr />
            {/* <Row>
                <Col sm={4}>
                  <span style={{ color: "gray" }}>Shipping</span>
                </Col>
                <Col sm={8}>
                  <p>
                    <Form.Check
                      type="radio"
                      name="shipping_radio"
                      checked={pickupAtShop}
                      onChange={(e) => {
                        setPickUpAtShop(e.target.value);
                      }}
                    />
                    Pickup at Shop
                  </p>
                  <p>
                    <Form.Check
                      type="radio"
                      name="shipping_radio"
                      checked={!pickupAtShop}
                      onChange={(e) => {
                        setPickUpAtShop(!e.target.value);
                      }}
                    />
                    Ship to {shipTo}
                    <span style={{ color: "blue", fontSize: "17px" }}>
                      Ksh. 300
                    </span>
                  </p>
                  {!pickupAtShop && (
                    <>
                      <Form.Group className="mb-3">
                        <Form.Label>County </Form.Label>
                        <Form.Select
                          value={shipTo}
                          onChange={(e) => setShipTo(e.target.value)}
                        >
                          {counties.map((s, index) => {
                            return (
                              <option value={s} key={index}>
                                {s}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>City </Form.Label>
                        <Form.Control type="text" required />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Specific Address </Form.Label>
                        <Form.Control type="text" required />
                      </Form.Group>
                    </>
                  )}
                </Col>
              </Row> */}
            <p>
              <span style={{ color: "gray" }}>Other Charges</span>
              <span style={{ color: "blue", fontSize: "17px" }}>Ksh. 0</span>
            </p>
            <hr />
            <p>
              <span style={{ color: "gray" }}>Total</span>{" "}
              <span style={{ color: "blue", fontSize: "17px" }}>
                Ksh. {sub_total}
              </span>
            </p>
            <br />

            <br />
            <Button disabled={(shopping_cart || []).length < 1}>
              <Link to={"/checkout/"}>PROCEED TO CHECKOUT</Link>
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CartPage;
