import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  // Carousel,
  Col,
  Container,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import PageNotFound from "../../components/PageNotFound";
// import StarRating from "../../components/StarRating";
// import MinusPlusContainer from "../../components/MinusPlusContainer";
// import { Heart } from "react-bootstrap-icons";
import GroupedProducts from "./GroupedProducts";
import RelatedProducts from "./RelatedProducts";
import axios from "axios";
// import { alterCart, deleteCart } from "../../store/cart/CartActions";
// import { useDispatch, useSelector } from "react-redux";
import { concatDuplicateKeyValues } from "../../CommonMethods";
import ProductMainDetails from "./ProductMainDetails";
import { Helmet } from "react-helmet-async";

const ProductPage = () => {
  const { productname } = useParams();

  let id = productname.split("-").slice(-1);

  // const dispatch = useDispatch();

  // const { loading, shopping_cart } = useSelector((state) => state.cart);

  const [product, setProduct] = useState(null);

  // const [percentOff, setPercentOff] = useState(0);

  const [fetching, setFetching] = useState(true);

  // const [inCartItem, setCartItem] = useState(null);

  // useEffect(() => {
  //   let r = (shopping_cart || []).find((x) => x.prod_id === parseInt(id));
  //   if (r) {
  //     setCartItem(r);
  //   }
  // }, [id, shopping_cart]);

  useEffect(() => {
    // let name = productname.slice(0, -2);

    // let p = productsList.find(
    //   (p) => p.id == id && p.name.toLowerCase() === name.toLowerCase()
    // );

    // setProduct(p);

    async function fetchProdOnline() {
      setFetching(true);
      if (parseInt(id)) {
        await axios
          .get(`${process.env.REACT_APP_API_URL}/product/${parseInt(id)}/`)
          .catch((_) => {})
          .then((response) => {
            if (response && response.status === 200) {
              setProduct(response.data);
            }
          });
      }

      setFetching(false);
    }

    fetchProdOnline();
    // eslint-disable-next-line
  }, [productname]);

  // useEffect(() => {
  //   function calculatePercentOff() {
  //     let perc = 0;
  //     if (product) {
  //       if (product.price && product.regular_price) {
  //         perc =
  //           ((product.regular_price - product.price) / product.regular_price) *
  //           100;
  //       }
  //     }

  //     return perc;
  //   }
  //   setPercentOff(calculatePercentOff());
  // }, [product]);

  function getCategories(paramList) {
    const elem = [];

    for (const i of paramList) {
      elem.push(
        <span key={i.id}>
          {" "}
          / <Link to={`/product-category/${i.name}/`}> {i.name}</Link>
        </span>
      );
    }

    return elem;
  }

  // const indicatorStyling = () => {
  //   let styling = ``;
  //   let index = 1;
  //   for (const image of product?.images) {
  //     styling = styling.concat(
  //       `
  //           .product_page .product_page_image .carousel-indicators [aria-label="Slide ${index}"]{
  //                   background-image: url("${process.env.REACT_APP_API_URL}${image?.src}");
  //                   background-position: center;
  //                   background-size: cover;
  //                   background-repeat: no-repeat;
  //               } \n`
  //     );
  //     index++;
  //   }
  //   return <> {styling}</>;
  // };

  // const onChange = (e) => {
  //   if (parseInt(e.target.value)) {
  //     if (e.target.value > 0 && product?.backorders_allowed) {
  //       if (inCartItem && inCartItem.prod_id) {
  //         dispatch(
  //           alterCart({
  //             id: inCartItem.id,
  //             prod_id: inCartItem.prod_id,
  //             quantity: parseInt(e.target.value),
  //             meta_data: inCartItem.meta_data,
  //           })
  //         );
  //       } else {
  //         dispatch(
  //           alterCart({
  //             id: null,
  //             prod_id: product.id,
  //             quantity: parseInt(e.target.value),
  //             meta_data: [],
  //           })
  //         );
  //       }
  //     } else if (
  //       e.target.value > 0 &&
  //       e.target.value <= product?.stock_quantity
  //     ) {
  //       if (inCartItem && inCartItem.prod_id) {
  //         dispatch(
  //           alterCart({
  //             id: inCartItem.id,
  //             prod_id: inCartItem.prod_id,
  //             quantity: parseInt(e.target.value),
  //             meta_data: inCartItem.meta_data,
  //           })
  //         );
  //       } else {
  //         dispatch(
  //           alterCart({
  //             id: null,
  //             prod_id: product.id,
  //             quantity: parseInt(e.target.value),
  //             meta_data: [],
  //           })
  //         );
  //       }
  //     }
  //   }
  // };

  // const onAdd = () => {
  //   if (inCartItem && inCartItem.prod_id) {
  //     if (product?.backorders_allowed) {
  //       dispatch(
  //         alterCart({
  //           id: inCartItem.id,
  //           prod_id: inCartItem.prod_id,
  //           quantity: inCartItem.quantity + 1,
  //           meta_data: inCartItem.meta_data,
  //         })
  //       );
  //     } else if (inCartItem.quantity + 1 <= product?.stock_quantity) {
  //       dispatch(
  //         alterCart({
  //           id: inCartItem.id,
  //           prod_id: inCartItem.prod_id,
  //           quantity: inCartItem.quantity + 1,
  //           meta_data: inCartItem.meta_data,
  //         })
  //       );
  //     }
  //   } else {
  //     dispatch(
  //       alterCart({
  //         id: null,
  //         prod_id: product.id,
  //         quantity: 1,
  //         meta_data: [],
  //       })
  //     );
  //   }
  // };

  // const onMinus = () => {
  //   if (inCartItem && inCartItem.prod_id) {
  //     if (inCartItem.quantity > 1) {
  //       dispatch(
  //         alterCart({
  //           id: inCartItem.id,
  //           prod_id: inCartItem.prod_id,
  //           quantity: inCartItem.quantity - 1,
  //           meta_data: inCartItem.meta_data,
  //         })
  //       );
  //     } else {
  //       dispatch(
  //         deleteCart({ id: inCartItem.id, prod_id: inCartItem.prod_id })
  //       );
  //       setCartItem(null);
  //     }
  //   }
  // };

  return (
    <div className="product_page">
      <div className="followLinks">
        <Link to={"/"}>Home </Link>
        {!fetching && product ? (
          <>
            {getCategories(product.categories || [])} / <i>{product?.name} </i>
          </>
        ) : (
          <>
            / <b>404 Not Found </b>
          </>
        )}
      </div>
      {fetching ? (
        <Container className="custom_container">
          <Row className="loading-product">
            <Col sm={5} className="loading-product-image">
              <img alt="" />
            </Col>
            <Col sm={6} className="product_page_details">
              <div className="loading-product-content-1">
                <img alt="" />
              </div>
              <div className="loading-product-content-2">
                <img alt="" />
              </div>
              <hr />
              <div className="loading-product-content-3">
                <img alt="" />
              </div>
              <div className="loading-product-content-4">
                <img alt="" />
              </div>
              <div className="product_page_details_buttons">
                <Button className="add_to_cart" disabled>
                  ADD TO CART
                </Button>
                <Button className="buy_now" disabled>
                  BUY NOW
                </Button>
              </div>
              <div className="loading-product-content-3">
                <img alt="" />
              </div>
              <div className="loading-product-content-4">
                <img alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      ) : product ? (
        <>
          <Helmet>
            <title>{product?.name}</title>
            <meta
              name="description"
              content={`Check out Oasis Auto Parts' ${product?.name}.`}
            />
            <link rel="canonical" href={`/shop/${productname}`} />
          </Helmet>
          <Container className="custom_container">
            <ProductMainDetails product={product} />
            {/* <Row>
            <Col sm={5} data-bs-theme="dark" className="product_page_image">
              {percentOff > 0 && (
                <div className="percantage_off">-{percentOff.toFixed(1)}%</div>
              )}
              <style>{indicatorStyling()}</style>
              <Carousel interval={null} fade controls={false}>
                {product?.images?.map((image, index) => {
                  return (
                    <Carousel.Item key={index}>
                      <img
                        src={`${process.env.REACT_APP_API_URL}${image?.src}`}
                        alt={`${process.env.REACT_APP_API_URL}${image?.alt}`}
                      />
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </Col>
            <Col sm={6} className="product_page_details">
              <p>
                {" "}
                <StarRating value={product?.average_rating} /> Reviews(
                {product?.rating_count})
              </p>
              <h2>{product?.name}</h2>
              <p style={{ display: "flex" }}>
                <h3 style={{ color: "blue", marginRight: "10px" }}>
                  Ksh. {product?.price}
                </h3>

                {percentOff > 0 && (
                  <span
                    style={{
                      color: "grey",
                      textDecoration: "line-through",
                    }}
                  >
                    Ksh. {product?.regular_price}
                  </span>
                )}
              </p>
              {product?.stock_status === "instock" ? (
                <></>
              ) : (
                // <p>
                //   <CheckCircle color="green" /> {product?.stock_quantity} in
                //   stock
                // </p>
                <p style={{ color: "red" }}>Out Of Stock</p>
              )}
              <h5 style={{ fontSize: "14px" }}>Quantity</h5>
              <MinusPlusContainer
                val={inCartItem?.quantity || 0}
                onChange={onChange}
                onAdd={onAdd}
                onMinus={onMinus}
                max={product?.stock_quantity}
                backOrdersAllowed={product?.backorders_allowed}
                disabled={product?.stock_status !== "instock"}
              />
              <div className="product_page_details_buttons">
                <Button
                  className="add_to_cart"
                  onClick={() =>
                    dispatch(
                      alterCart({
                        id: null,
                        prod_id: product.id,
                        quantity: 1,
                        meta_data: [],
                      })
                    )
                  }
                  disabled={
                    loading ||
                    inCartItem !== null ||
                    product?.stock_status !== "instock"
                  }
                >
                  {inCartItem !== null ? "ADDED" : "ADD TO CART"}
                </Button>
                <Button className="buy_now">BUY NOW</Button>
              </div>
              <Link>
                <Heart /> Add to wishlist
              </Link>
              <hr />
              {product?.brand?.name !== "Other" && (
                <p className="p_re">
                  <b>Manufacturer/Brand:</b> {product?.brand?.name}
                </p>
              )}
              <p className="p_re">
                <b>TAGS:</b> {product?.tags}{" "}
              </p>
              {/* <p className="p_re">
                <b>MODEL:</b> {product?.model}{" "}
              </p> 
            </Col>
          </Row> */}
            <br />
            {/* check if grouped_products not empty */}
            {(product?.grouped_products ?? []).length > 0 && (
              <GroupedProducts
                this_product={product}
                grouped_products={product?.grouped_products ?? []}
              />
            )}
            <br />
            <div className="product_page_more_info" itemProp="description">
              <Tabs defaultActiveKey="description" className="mb-3" fill>
                <Tab eventKey={"description"} title="DESCRIPTION">
                  {product?.description}

                  {product?.features && (
                    <div className="product_key_features">
                      <h5>Key Features</h5>
                      <ul>
                        {product?.features.split("\n").map((item, index) => {
                          return <li key={index}> {item}</li>;
                        })}
                      </ul>
                    </div>
                  )}
                </Tab>
                <Tab eventKey={"additionalinfo"} title="ADDITIONAL INFORMATION">
                  <Table striped bordered>
                    <tbody>
                      {concatDuplicateKeyValues(product?.meta_data ?? []).map(
                        (element, index) => {
                          return (
                            <tr key={index}>
                              <td style={{ fontWeight: "600" }}>
                                {element?.key?.toUpperCase()}
                              </td>
                              <td>{element?.value}</td>
                            </tr>
                          );
                        }
                      )}
                      {product?.dimensions !== null && (
                        <tr>
                          <td style={{ fontWeight: "600" }}>DIMENSION</td>
                          <td>
                            {product?.dimensions?.height} x
                            {product?.dimensions?.width} x
                            {product?.dimensions?.length}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Tab>
                {/* <Tab eventKey={"review"} title="REVIEWS">
                Reviews
              </Tab> */}
              </Tabs>
            </div>
            <br />
            <RelatedProducts
              product_categories={product?.categories}
              related_products={product?.related_ids ?? []}
            />
          </Container>
        </>
      ) : (
        <PageNotFound />
      )}
    </div>
  );
};

export default ProductPage;
