import React from "react";
import whatsapp from "../assets/icons/whatsapp-svg-white-green.svg";

const FloatingContact = () => {
  return (
    <div className="floating_contact">
      <a
        href="https://wa.me/message/6TX6YORHXUIHO1"
        target="_blank"
        rel="noreferrer"
      >
        <img src={whatsapp} alt="nav1" />
      </a>
    </div>
  );
};

export default FloatingContact;
