import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CheckoutCartContainer from "./CheckoutCartContainer";
import { ArrowRight, Asterisk } from "react-bootstrap-icons";
import { validateEmail, validatePhoneNumber } from "../../CommonMethods";
import axios from "axios";
import { checkIsAuthenticated, sign_up } from "../../store/user/UserActions";
import { basicConfig } from "../../constants";
import LoadingDiv from "../../components/LoadingDiv";
import { fetchCart } from "../../store/cart/CartActions";

const counties = [
  "Nairobi",
  "Mombasa",
  "Kwale",
  "Kilifi",
  "Tana River",
  "Lamu",
  "Taita/Taveta",
  "Garissa",
  "Wajir",
  "Mandera",
  "Marsabit",
  "Isiolo",
  "Meru",
  "Tharaka-Nithi",
  "Embu",
  "Kitui",
  "Machakos",
  "Makueni",
  "Nyandarua",
  "Nyeri",
  "Kirinyaga",
  "Murang'a",
  "Kiambu",
  "Turkana",
  "West Pokot",
  "Samburu",
  "Trans Nzoia",
  "Uasin Gishu",
  "Elgeyo/Marakwet",
  "Nandi",
  "Baringo",
  "Laikipia",
  "Nakuru",
  "Narok",
  "Kajiado",
  "Kericho",
  "Bomet",
  "Kakamega",
  "Vihiga",
  "Bungoma",
  "Busia",
  "Siaya",
  "Kisumu",
  "Homa Bay",
  "Migori",
  "Kisii",
  "Nyamira",
];

const CheckOut = () => {
  const { isAuthenticated, user, error } = useSelector((state) => state.user);

  const { shopping_cart, sub_total, shopping_cart_id } = useSelector(
    (state) => state.cart
  );

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    re_password: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    address: {
      address: "",
      town: "",
      county: "Nairobi",
      country: "Kenya",
    },
  });

  const {
    email,
    first_name,
    password,
    re_password,
    last_name,
    phone_number,
    address,
  } = formData;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [lineItems, setLineItems] = useState([]);

  const [loading, setLoading] = useState(false);

  const [errorMsg, setErrorMsg] = useState(null);

  const [createUser, setCreateUser] = useState(false);

  const [formValidated, setFormValidated] = useState(false);

  const [isPhoneNumberValid, setPhoneNumberValid] = useState(false);

  const [isEmailValid, setIsEmailValid] = useState(false);

  const [pickupAtShop, setPickUpAtShop] = useState(true);

  const [billingAddressId, setBillingAddressId] = useState(null);

  const [shippingAddressId, setShippingAddressId] = useState(null);

  const [shipTo, setShipTo] = useState({
    address: "",
    town: "",
    county: "Nairobi",
    country: "Kenya",
  });

  // eslint-disable-next-line
  const [shippingCharge, setShippingCharge] = useState(500);

  const [total, setTotal] = useState(sub_total);

  const [paymentMethod, setPaymentMethod] = useState("mpesa");

  const [customerNote, setCustomerNote] = useState("");

  async function fetchShoppingCartProducts() {
    let line_items = [];
    for (const cart_item of shopping_cart ?? []) {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/product/${cart_item.prod_id}/`)
        .catch((_) => {})
        .then((response) => {
          if (response && response.status === 200) {
            let product = response.data;
            line_items.push({
              product: product.id,
              name: product.name,
              quantity: cart_item.quantity,
              price: product.price,
              total: product.price * cart_item.quantity,
              total_tax: 0,
              meta_data: [],
              image: product.images[0].id,
            });
          }
        });
    }
    setLineItems(line_items);
  }

  useEffect(() => {
    fetchShoppingCartProducts();
    // eslint-disable-next-line
  }, [shopping_cart]);

  useEffect(() => {
    setErrorMsg(error);
  }, [error]);

  useEffect(() => {
    if (user != null) {
      let { email, name, phone_number, address } = user;

      setFormData((f) => ({
        ...f,
        email: email,
        first_name: name.split(" ")[0] ?? "",
        last_name: name?.split(" ")[1] ?? "",
        phone_number: phone_number,
        address: { ...f.address, ...address },
      }));
    }
  }, [user]);

  useEffect(() => {
    setIsEmailValid(validateEmail(email));
    setPhoneNumberValid(validatePhoneNumber(phone_number));
  }, [email, phone_number]);

  useEffect(() => {
    if (!pickupAtShop) {
      setTotal(sub_total + shippingCharge);
    } else {
      setTotal(sub_total);
    }
  }, [shippingCharge, pickupAtShop, sub_total]);

  async function createOrder(customer, billing, shippng) {
    setLoading(true);

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/orders/`,
        {
          created_via: "Web",
          customer: customer,
          status: "pending",
          currency: "Ksh",
          shipping_total: pickupAtShop ? 0 : shippingCharge,
          total: total,
          prices_include_tax: true,
          customer_note: customerNote,
          customer_user_agent: null,
          billing: billing,
          shipping: shippng,
          payment_method: paymentMethod,
          is_editable: true,
          needs_payment: true,
          line_items: lineItems,
        },
        basicConfig
      )
      .catch((_) => {
        setErrorMsg("Error! Try again later or contact customer care.");
        window.scrollTo(0, 0);
        setLoading(false);
      })
      .then((response) => {
        if (response && response.status === 200) {
          let order = response.data;
          if (!isAuthenticated && !createUser) {
            let local_orders =
              JSON.parse(localStorage.getItem("oasis_orders")) || [];
            local_orders.push({
              id: order.id,
              status: order.status,
              currency: order.currency,
              date_created_gmt: order.date_created_gmt,
              shipping_total: order.shipping_total,
              total: order.total,
              customer_note: order.customer_note,
              payment_method: order.payment_method,
              customer: order.customer,
              billing: order.billing,
              shipping: order.shipping,
              line_items: order.line_items,
            });
            localStorage.setItem("oasis_orders", JSON.stringify(local_orders));
            localStorage.removeItem("cart");
            dispatch(fetchCart());
            setLoading(false);
            navigate("/orders/?user=guest");
          } else {
            setLoading(false);

            axios
              .post(
                `${process.env.REACT_APP_API_URL}/orders/convert_cart/`,
                { id: shopping_cart_id },
                basicConfig
              )
              .catch((_) => {});
            dispatch(fetchCart());
            navigate("/orders/");
          }
        } else {
          setErrorMsg("Error! Try again later or contact customer care.");
          window.scrollTo(0, 0);
          setLoading(false);
        }
      });
  }

  async function handleShipping(user_mail, billing_id) {
    setLoading(true);
    if (shippingAddressId == null && !pickupAtShop) {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/users/address/`,
          {
            ...shipTo,
            first_name: first_name,
            last_name: last_name,
            email: email,
            phone_number: phone_number,
          },
          basicConfig
        )
        .catch((_) => {
          setErrorMsg("Error creating shipping address.");
          setLoading(false);
        })
        .then(async (response) => {
          if (response && response.status === 200) {
            let shipping_id = response.data?.id;
            setShippingAddressId(response.data?.id);
            await createOrder(user_mail, billing_id, shipping_id);
          }
        });
    } else {
      if (pickupAtShop) {
        await createOrder(user_mail, billing_id);
      } else {
        await createOrder(user_mail, billing_id, shippingAddressId);
      }
    }
  }

  async function updateUserDetails(userDict) {
    setLoading(true);
    await axios
      .put(
        `${process.env.REACT_APP_API_URL}/users/${userDict.email}/`,
        userDict,
        basicConfig
      )
      .catch((e) => {
        setErrorMsg("Error updating user details.");
        setLoading(false);
      })
      .then(async (response) => {
        if (response && response.status === 200) {
          dispatch(checkIsAuthenticated());
          await handleShipping(userDict.email, userDict?.address);
        }
      });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    setErrorMsg(null);

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setFormValidated(true);
      window.scrollTo(0, 0);
      setLoading(false);
    } else {
      if (isAuthenticated) {
        if (isPhoneNumberValid) {
          let modifiedFormData = {
            email: email,
            name: `${first_name} ${last_name}`,
            phone_number: phone_number,
            address: address,
          };

          let id = address?.id;

          if (id == null && billingAddressId == null) {
            // create
            await axios
              .post(
                `${process.env.REACT_APP_API_URL}/users/address/`,
                {
                  ...address,
                  first_name: first_name,
                  last_name: last_name,
                  email: email,
                  phone_number: phone_number,
                },
                basicConfig
              )
              .catch((_) => {
                setErrorMsg("Error creating billing address.");
                window.scrollTo(0, 0);
                setLoading(false);
              })
              .then(async (response) => {
                if (response && response.status === 200) {
                  id = response.data?.id;
                  setBillingAddressId(id);
                  modifiedFormData.address = id;
                  await updateUserDetails(modifiedFormData);
                }
              });
          } else {
            // update
            setBillingAddressId(id ?? billingAddressId);
            await axios
              .put(
                `${process.env.REACT_APP_API_URL}/users/address/${id}/`,
                {
                  ...address,
                  first_name: first_name,
                  last_name: last_name,
                  email: email,
                  phone_number: phone_number,
                },
                basicConfig
              )
              .catch((_) => {
                setErrorMsg("Error updating address.");
                window.scrollTo(0, 0);
                setLoading(false);
              })
              .then(async (response) => {
                if (response && response.status === 200) {
                  modifiedFormData.address = id;
                  await updateUserDetails(modifiedFormData);
                }
              });
          }
        }
      } else if (createUser) {
        if (password === re_password && isEmailValid && isPhoneNumberValid) {
          let newUserDetails = {
            name: `${first_name} ${last_name}`,
            phone_number: phone_number,
            email: email,
            password: password,
            re_password: re_password,
          };

          dispatch(sign_up(newUserDetails)).then(async (res) => {
            if (res.payload) {
              //   create address
              await axios
                .post(
                  `${process.env.REACT_APP_API_URL}/users/address/`,
                  {
                    ...address,
                    first_name: first_name,
                    last_name: last_name,
                    email: email,
                    phone_number: phone_number,
                  },
                  basicConfig
                )
                .catch((_) => {
                  setErrorMsg("Error creating address.");
                  window.scrollTo(0, 0);
                  setLoading(false);
                })
                .then(async (response) => {
                  if (response && response.status === 200) {
                    setBillingAddressId(response.data?.id);
                    await updateUserDetails({
                      name: `${first_name} ${last_name}`,
                      phone_number: phone_number,
                      email: email,
                      address: response.data?.id,
                    });
                  }
                });
            } else {
              setLoading(false);
            }
          });
        }
      } else {
        if (billingAddressId == null) {
          await axios
            .post(
              `${process.env.REACT_APP_API_URL}/users/address/`,
              {
                ...address,
                first_name: first_name,
                last_name: last_name,
                email: email,
                phone_number: phone_number,
              },
              basicConfig
            )
            .catch((_) => {
              window.scrollTo(0, 0);
              setErrorMsg("Error creating address.");
              setLoading(false);
            })
            .then(async (response) => {
              if (response && response.status === 200) {
                let billing_id = response.data?.id;
                setBillingAddressId(billing_id);
                await handleShipping(null, billing_id);
              }
            });
        } else {
          await handleShipping(null, billingAddressId);
        }
      }
    }
  }

  return (
    <div className="checkout_page">
      {loading && <LoadingDiv />}
      <div className="followLinks">
        <Link to={"/"}>Home </Link>/ <Link to={`/checkout/`}>Checkout </Link>
      </div>
      <br />
      <h4>
        <Link to={"/cart/"}>SHOPPING CART</Link> <ArrowRight color="grey" />{" "}
        <span style={{ textDecoration: "underline" }}>CHECKOUT</span>{" "}
        <ArrowRight color="grey" />{" "}
        <span style={{ color: "grey" }}> ORDER COMPLETE</span>
      </h4>
      <br />
      {!isAuthenticated && (
        <div style={{ textAlign: "left", padding: "0 20px" }}>
          <Link to={"/login/?redirect=checkout/"}>
            Returning Customer? Login
          </Link>
        </div>
      )}
      <br />
      <Container className="custom_container">
        <Form
          noValidate
          validated={formValidated}
          onSubmit={(e) => handleSubmit(e)}
        >
          <Row>
            <Col sm={7} className="billing_details">
              <h4>BILLING DETAILS</h4>
              {errorMsg && (
                <Alert variant="danger" autoFocus={true}>
                  <Alert.Heading>{error}</Alert.Heading>
                </Alert>
              )}
              <Row className="mb-3">
                <Form.Group as={Col} md="6">
                  <Form.Label>
                    First Name <Asterisk color="red" size={5} />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="first_name"
                    required
                    maxLength={64}
                    value={first_name}
                    onChange={(e) => {
                      setFormData({ ...formData, first_name: e.target.value });
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter First Name.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label>
                    Last Name <Asterisk color="red" size={5} />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="last_name"
                    required
                    maxLength={64}
                    value={last_name}
                    onChange={(e) => {
                      setFormData({ ...formData, last_name: e.target.value });
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Last Name.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Form.Group className="mb-3">
                <Form.Label>
                  Phone Number <Asterisk color="red" size={5} />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="phone_number"
                  className={
                    isPhoneNumberValid
                      ? "custom-validate-class-valid"
                      : "me-2  custom-validate-class-invalid"
                  }
                  isInvalid={!isPhoneNumberValid}
                  required
                  maxLength={15}
                  value={phone_number}
                  onChange={(e) => {
                    setFormData({ ...formData, phone_number: e.target.value });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter A Valid Phone Number.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  Email <Asterisk color="red" size={5} />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  disabled={isAuthenticated}
                  className={
                    isEmailValid
                      ? "custom-validate-class-valid"
                      : "me-2  custom-validate-class-invalid"
                  }
                  isInvalid={!isEmailValid}
                  required
                  maxLength={64}
                  value={email}
                  onChange={(e) => {
                    setFormData({ ...formData, email: e.target.value });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter a Valid Email Address
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  Specify Address <Asterisk color="red" size={5} />
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="address"
                  value={address?.address ?? ""}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      address: { ...address, address: e.target.value },
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  Town / City <Asterisk color="red" size={5} />
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="town"
                  value={address?.town ?? ""}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      address: { ...address, town: e.target.value },
                    })
                  }
                />
              </Form.Group>
              <Row>
                <Form.Group as={Col} md="6">
                  <Form.Label>County</Form.Label>
                  <Form.Select
                    value={address?.county ?? "Nairobi"}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        address: { ...address, county: e.target.value },
                      })
                    }
                  >
                    {counties.map((s, index) => {
                      return (
                        <option value={s} key={index}>
                          {s}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    name="country"
                    value={address?.country ?? "Kenya"}
                  />
                </Form.Group>
              </Row>
              <p></p>
              <Form.Check
                value={createUser}
                label="Create User? Be able to track your order."
                onChange={(e) => setCreateUser(e.target.checked)}
              />
              <p></p>
              {!isAuthenticated && createUser && (
                <>
                  <Form.Group className="mb-3">
                    <Form.Label>Password </Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      minLength={8}
                      required
                      value={password}
                      onChange={(e) =>
                        setFormData({ ...formData, password: e.target.value })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {password.length < 1
                        ? "Please Enter A Password."
                        : "Pasword Not less that 8 Characters"}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Confirm Password </Form.Label>
                    <Form.Control
                      type="password"
                      name="re_password"
                      isInvalid={password !== re_password}
                      className={
                        password.length > 0
                          ? password === re_password
                            ? "custom-validate-class-valid"
                            : "me-2  custom-validate-class-invalid"
                          : ""
                      }
                      required
                      value={re_password}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          re_password: e.target.value,
                        })
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {re_password.length < 1
                        ? "Please Confirm Password."
                        : "Password Should Match"}
                    </Form.Control.Feedback>
                  </Form.Group>
                </>
              )}
            </Col>

            <Col sm={5} className="order_checkout">
              <h4>Your Order</h4>
              <hr />
              {lineItems.map((element, index) => {
                return (
                  <CheckoutCartContainer key={index} line_item={element} />
                );
              })}
              <hr />
              <p>
                <span style={{ color: "gray" }}>Sub Total</span>{" "}
                <span style={{ color: "blue", fontSize: "17px" }}>
                  Ksh. {sub_total}
                </span>
              </p>
              <hr />
              <Row>
                <Col sm={4}>
                  <span style={{ color: "gray" }}>Shipping</span>
                </Col>
                <Col sm={8}>
                  <Form.Check
                    type="radio"
                    name="shipping_radio"
                    label="Pickup at Shop"
                    checked={pickupAtShop}
                    onChange={(e) => {
                      setPickUpAtShop(e.target.value);
                    }}
                  />

                  <p></p>

                  <Form.Check
                    type="radio"
                    label={
                      <span>
                        Ship to {shipTo.county}
                        <span style={{ color: "blue", fontSize: "17px" }}>
                          {" "}
                          Ksh. {shippingCharge}
                        </span>
                      </span>
                    }
                    name="shipping_radio"
                    checked={!pickupAtShop}
                    onChange={(e) => {
                      setPickUpAtShop(!e.target.value);
                    }}
                  />

                  {!pickupAtShop && (
                    <>
                      <Form.Group className="mb-3">
                        <Form.Label>County </Form.Label>
                        <Form.Select
                          value={shipTo.county}
                          onChange={(e) =>
                            setShipTo((p) => ({ ...p, county: e.target.value }))
                          }
                        >
                          {counties.map((s, index) => {
                            return (
                              <option value={s} key={index}>
                                {s}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Town / City <Asterisk size={5} color="red" />{" "}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          required
                          value={shipTo.town}
                          onChange={(e) =>
                            setShipTo((p) => ({ ...p, town: e.target.value }))
                          }
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Specific Address <Asterisk size={5} color="red" />
                        </Form.Label>
                        <Form.Control
                          type="text"
                          required
                          value={shipTo.address}
                          onChange={(e) =>
                            setShipTo((p) => ({
                              ...p,
                              address: e.target.value,
                            }))
                          }
                        />
                      </Form.Group>
                    </>
                  )}
                </Col>
              </Row>

              <hr />
              <p>
                <span style={{ color: "gray" }}>Total</span>{" "}
                <span style={{ color: "blue", fontSize: "17px" }}>
                  Ksh. {total}
                </span>
              </p>
              <br />

              <Form.Group className="mb-3">
                <label htmlFor="description">Note</label>
                <textarea
                  name="note"
                  className="form-control"
                  placeholder="Type any requirements..."
                  maxLength={800}
                  value={customerNote}
                  rows={4}
                  onChange={(e) => setCustomerNote(e.target.value)}
                ></textarea>
              </Form.Group>
              <h6>Payment</h6>
              <Form.Check
                type="radio"
                name="payment_method"
                checked={paymentMethod === "mpesa"}
                label="M Pesa"
                onChange={(e) => {
                  if (e.target.value) {
                    setPaymentMethod("mpesa");
                  }
                }}
              />

              {paymentMethod === "mpesa" && (
                <Card body>Paybill 522533: Acc 7895917 : Oasis Auto Parts</Card>
              )}
              <p></p>
              <Form.Check
                type="radio"
                name="payment_method"
                checked={paymentMethod === "cash"}
                label="Cash"
                onChange={(e) => {
                  if (e.target.value) {
                    setPaymentMethod("cash");
                  }
                }}
              />

              <br />
              <Button type="submit" disabled={loading || lineItems.length < 1}>
                PLACE ORDER
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default CheckOut;
