import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import OrderItem from "./OrderItem";
import axios from "axios";
import { basicConfig } from "../../constants";

const MyOrders = () => {
  const { isAuthenticated, user } = useSelector((state) => state.user);

  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);

  const guest = urlParams.get("user");

  const [customerOrders, setCustomerOrders] = useState([]);

  const [localOrders, setLocalOrders] = useState([]);

  async function getOrders() {
    if (isAuthenticated) {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/orders/filter_order/`,
          {
            customer: user?.email,
          },
          basicConfig
        )
        .catch((_) => {})
        .then((response) => {
          if (response && response.status === 200) {
            setCustomerOrders(response.data);
          }
        });
    }
  }

  useEffect(() => {
    getOrders();
    // eslint-disable-next-line
  }, [isAuthenticated]);

  useEffect(() => {
    if (guest === "guest") {
      let local_orders = JSON.parse(localStorage.getItem("oasis_orders")) || [];
      setLocalOrders(local_orders);
    }
  }, [guest]);
  return (
    <div className="orders_page">
      <Container className="custom_container">
        {isAuthenticated ? (
          customerOrders.length > 0 ? (
            <Row>
              {customerOrders.map((order, index) => {
                return <OrderItem key={index} order_item={order} />;
              })}
            </Row>
          ) : (
            <center>No Orders Found.</center>
          )
        ) : guest === "guest" ? (
          localOrders.length > 0 ? (
            <Row>
              {localOrders.map((order, index) => {
                return <OrderItem key={index} order_item={order} />;
              })}
            </Row>
          ) : (
            <center>No Orders Found.</center>
          )
        ) : (
          <center>
            <Link to={"/login/?redirect=orders/"}> Login To View Orders</Link>
          </center>
        )}
      </Container>
    </div>
  );
};

export default MyOrders;
