import React, { useEffect, useState } from "react";
import { Alert, Button, Dropdown, Form, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import PMetaDataForm from "./ProductFormComponents/PMetaDataForm";
import PTagsForm from "./ProductFormComponents/PTagsForm";
import PImageForm from "./ProductFormComponents/PImageForm";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import AdminPage from "../AdminPage";
import PCategoryForm from "./ProductFormComponents/PCategoryForm";
// import PBrandForm from "./ProductFormComponents/PBrandForm";
import { isArray, return_ids_from_objects } from "../../../CommonMethods";
import { basicConfig, basicConfigMultipart } from "../../../constants";
import { fetchData } from "../../../store/data/DataAction";
import M2MRelation from "./ProductFormComponents/M2MRelation";
import PBrandForm from "./ProductFormComponents/PBrandForm";

const productModel = {
  id: null,
  name: "",
  model: "",
  whats_in_box: "",
  description: "",
  short_description: "...",
  features: "",
  price: "",
  regular_price: "",
  sale_price: "",
  featured: false,
  sold_individually: true,
  on_sale: true,
  purchasable: true,
  manage_stock: true,
  stock_quantity: 1000,
  stock_status: "instock",
  backorders_allowed: true,
  weight: "",
  reviews_allowed: true,
  average_rating: 0,
  rating_count: 0,
  tags: "",
  brand: null,
  done_by: "",
  dimensions: {
    id: null,
    height: "",
    width: "",
    length: "",
  },
  downloads: [],
  grouped_products: [],
  related_ids: [],
  categories: [],
  images: [],
  meta_data: [],
};

const stockStatuses = [
  { key: "IN STOCK", value: "instock" },
  { key: "OUT-OF-STOCK", value: "outofstock" },
  { key: "ON-BACKORDER", value: "onbackorder" },
];

const ProductForm = () => {
  const { pk } = useParams();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [showError, setShowError] = useState(false);

  const [product, setProduct] = useState(productModel);

  const [formValidated, setFormValidated] = useState(false);

  const { user } = useSelector((state) => state.user);

  const [imagesToDelete, setImagesToDelete] = useState([]);

  async function fetchProductById(id) {
    setShowError(false);
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/product/${id}/`)
      .catch((_) => {
        setShowError(true);
      })
      .then((response) => {
        if (response && response.status === 200) {
          setProduct({ ...productModel, ...response.data });
        } else {
          setShowError(true);
        }
      });

    setLoading(false);
  }
  useEffect(() => {
    //   fetch the item using pk as id
    if (pk) {
      fetchProductById(pk);
    }
  }, [pk]);

  useEffect(() => {
    let i = stockStatuses.findIndex((p) => {
      return p.value === product.stock_status;
    });
    if (i >= 0) {
      setStockStatus(i);
    }
  }, [product.stock_status]);

  function removeItem(index, slist) {
    slist.splice(index, 1);
    return slist;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setFormValidated(true);
      window.scrollTo(0, 0);
    } else {
      setShowError(false);
      setFormValidated(false);
      setLoading(true);

      // what we need to do,
      // convert list of {} or {} into ids, -> fn that returns id if key of id has non-null value, otherwise creates object in table and return id
      // for product, object that will be affected
      // brand, dimensions, related_ids, categories, images, metadata
      // file objects will need a different fn
      let modified_product = { ...product };

      let categories_ids = [];
      for (const obj of modified_product.categories) {
        const id = await return_ids_from_objects(
          obj,
          `${process.env.REACT_APP_API_URL}/category/`,
          false
        );
        if (id !== null) {
          categories_ids.push(id);
        }
      }

      modified_product.categories = categories_ids;

      // grouped products m2m
      let grouped_products_ids = [];

      for (const obj of modified_product.grouped_products) {
        const id = await return_ids_from_objects(obj, ``, false);
        if (id !== null) {
          grouped_products_ids.push(id);
        }
      }

      modified_product.grouped_products = grouped_products_ids;

      //  related products m2m
      let related_products_ids = [];

      for (const obj of modified_product.related_ids) {
        const id = await return_ids_from_objects(obj, ``, false);
        if (id !== null) {
          related_products_ids.push(id);
        }
      }

      modified_product.related_ids = related_products_ids;

      let meta_data_ids = [];
      for (const obj of modified_product.meta_data) {
        const id = await return_ids_from_objects(
          obj,
          `${process.env.REACT_APP_API_URL}/metadata/`,
          false
        );
        if (id !== null) {
          meta_data_ids.push(id);
        }
      }

      modified_product.meta_data = meta_data_ids;

      let images_ids = [];
      for (const obj of modified_product.images) {
        const id = await return_ids_from_objects(
          obj,
          `${process.env.REACT_APP_API_URL}/images/`,
          false
        );
        if (id !== null) {
          images_ids.push(id);
        }
      }

      modified_product.images = images_ids;

      if (product.brand === null) {
        // setting `Other` as default
        modified_product.brand = 3;
      } else {
        modified_product.brand = await return_ids_from_objects(
          product.brand,
          `${process.env.REACT_APP_API_URL}/brands/`,
          false
        );
      }

      modified_product.dimensions = await return_ids_from_objects(
        product.dimensions,
        `${process.env.REACT_APP_API_URL}/dimensions/`,
        true
      );

      const formData = new FormData();

      for (const [key, value] of Object.entries(modified_product)) {
        if (value != null) {
          //   image sent should be a file not a string, so we'll append image elsewhere
          if (isArray(value)) {
            value.forEach((i) => {
              formData.append(`${key}`, i);
            });
          } else {
            formData.append(`${key}`, value);
          }
        }
      }

      // let slug = modified_product.name.toLowerCase();

      // formData.set("slug", slug);

      formData.set("done_by", user.email);

      if (pk) {
        //   updating
        await axios
          .put(
            `${process.env.REACT_APP_API_URL}/product/${pk}/`,
            formData,
            basicConfigMultipart
          )
          .catch((_) => {
            setShowError(true);
          })
          .then(async (response) => {
            if (response && response.status === 200) {
              axios
                .post(
                  "https://script.google.com/macros/s/AKfycbyBtL7SsDxv4ca-nZlndelMdks072NAygTTsYAyYVrSiIMjBDrUeh_mg_1Q-Q7rOTzftw/exec?action=update",
                  JSON.stringify(response.data),
                  {
                    headers: {
                      "Content-Type": "text/plain",
                    },
                  }
                )
                .catch((_) => {
                  console.log(_);
                });
              // delete previous images if unlinked from product
              if (imagesToDelete.length > 0) {
                for (const imageId of imagesToDelete) {
                  try {
                    await axios.delete(
                      `${process.env.REACT_APP_API_URL}/images/${imageId}/`,
                      basicConfig
                    );
                  } catch (_) {}
                }
              }

              navigate("/admin/products/");
            } else {
              setShowError(true);
            }
          });
      } else {
        // posting
        await axios
          .post(
            `${process.env.REACT_APP_API_URL}/`,
            formData,
            basicConfigMultipart
          )
          .catch((_) => {
            setShowError(true);
          })
          .then((response) => {
            if (response && response.status === 200) {
              axios
                .post(
                  "https://script.google.com/macros/s/AKfycbyBtL7SsDxv4ca-nZlndelMdks072NAygTTsYAyYVrSiIMjBDrUeh_mg_1Q-Q7rOTzftw/exec",
                  JSON.stringify(response.data),
                  {
                    headers: {
                      "Content-Type": "text/plain",
                    },
                  }
                )
                .catch((_) => {});
              navigate("/admin/products/");
            } else {
              setShowError(true);
            }
          });
      }
      dispatch(fetchData());
      setLoading(false);
    }
  }

  const [selectedStockStatus, setStockStatus] = useState(0);

  return (
    <AdminPage>
      {showError && (
        <Alert
          variant="danger"
          autoFocus={true}
          onClose={() => {
            setShowError(false);
          }}
          dismissible
        >
          {window.scrollTo(0, 0)}
          <Alert.Heading>Error. Try Again.</Alert.Heading>
        </Alert>
      )}
      {loading && <Spinner animation="grow" />}
      <Form
        noValidate
        validated={formValidated}
        onSubmit={(e) => handleSubmit(e)}
        encType="multipart/form-data"
      >
        <Form.Group className="mb-3">
          <Form.Label>Name </Form.Label>
          <Form.Control
            type="text"
            required
            maxLength={64}
            value={product.name || ""}
            onChange={(e) => {
              setProduct({
                ...product,
                name: e.target.value.trimStart(),
                slug: (e.target.value.trimStart() ?? "").toLowerCase(),
              });
            }}
          />
          <Form.Control.Feedback type="invalid">
            Please type product name.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <label htmlFor="description">Description</label>
          <textarea
            name="description"
            required
            className="form-control"
            maxLength={800}
            value={product.description || ""}
            rows={4}
            onChange={(e) =>
              setProduct({
                ...product,
                description: e.target.value.trimStart(),
              })
            }
          ></textarea>
          <Form.Control.Feedback type="invalid">
            Please provide a full description.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <label htmlFor="short_desc">Short Description (Opt.)</label>
          <textarea
            name="short_desc"
            required
            maxLength={200}
            className="form-control"
            value={product.short_description || ""}
            rows={2}
            onChange={(e) =>
              setProduct({
                ...product,
                short_description: e.target.value.trimStart(),
              })
            }
          ></textarea>
          <Form.Control.Feedback type="invalid">
            Please provide a short description.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <label htmlFor="whats_in_box">What's In Box (Opt.)</label>
          <textarea
            name="whats_in_box"
            className="form-control"
            value={product.whats_in_box || ""}
            rows={4}
            onChange={(e) =>
              setProduct({
                ...product,
                whats_in_box: e.target.value.trimStart(),
              })
            }
          ></textarea>
        </Form.Group>
        <Form.Group className="mb-3">
          <label htmlFor="features">Features (Opt.)</label>
          <textarea
            name="features"
            className="form-control"
            value={product.features || ""}
            rows={4}
            onChange={(e) =>
              setProduct({ ...product, features: e.target.value.trimStart() })
            }
          ></textarea>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Price </Form.Label>
          <Form.Control
            type="number"
            required
            value={product.price || ""}
            onChange={(e) =>
              setProduct({
                ...product,
                price: e.target.value,
                sale_price: e.target.value,
              })
            }
          />
          <Form.Control.Feedback type="invalid">
            Please provide product price
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Regular Price (Before Discount) </Form.Label>
          <Form.Control
            type="number"
            required
            value={product.regular_price || ""}
            onChange={(e) =>
              setProduct({
                ...product,
                regular_price: e.target.value,
              })
            }
          />
          <Form.Control.Feedback type="invalid">
            Please provide product regular price
          </Form.Control.Feedback>
        </Form.Group>
        {/* <Form.Group className="mb-3">
          <Form.Label>Sale Price </Form.Label>
          <Form.Control
            type="number"
            required
            value={product.sale_price || ""}
            
            // onChange={(e) =>
            //   setProduct({ ...product, sale_price: e.target.value })
            // }
          />
          <Form.Control.Feedback type="invalid">
            Please provide product sale price
          </Form.Control.Feedback>
        </Form.Group> */}

        <PImageForm
          product={product}
          setProduct={setProduct}
          imagesToDelete={imagesToDelete}
          setImagesToDelete={setImagesToDelete}
        />

        {/* <Form.Group className="mb-3">
          <Form.Check
            type="checkbox"
            label="On-Sale"
            checked={product.on_sale || true}
            onChange={(e) =>
              setProduct({ ...product, on_sale: e.target.checked })
            }
          />
        </Form.Group> */}
        <Form.Group className="mb-3">
          <Form.Check
            type="checkbox"
            label="Purchaseable"
            checked={product.purchasable || true}
            onChange={(e) =>
              setProduct({ ...product, purchasable: e.target.checked })
            }
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Check
            type="checkbox"
            label="Popular"
            checked={product.featured || false}
            onChange={(e) =>
              setProduct({ ...product, featured: e.target.checked })
            }
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Stock Quantity </Form.Label>
          <Form.Control
            type="number"
            required
            value={product.stock_quantity}
            onChange={(e) =>
              setProduct({ ...product, stock_quantity: e.target.value })
            }
          />
          <Form.Control.Feedback type="invalid">
            Provide an est. stock quantity
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Stock Status </Form.Label>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              {stockStatuses[selectedStockStatus].key}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {stockStatuses.map((s, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    onClick={() => {
                      setProduct({ ...product, stock_status: s.value });
                    }}
                  >
                    {s.key}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Check
            type="checkbox"
            label="Backorders Allowed"
            checked={product.backorders_allowed}
            onChange={(e) =>
              setProduct({ ...product, backorders_allowed: e.target.checked })
            }
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Weight (Opt.)</Form.Label>
          <Form.Control
            type="text"
            value={product.weight ? product.weight : ""}
            onChange={(e) =>
              setProduct({ ...product, weight: e.target.value.trimStart() })
            }
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Dimensions (Opt.)</Form.Label>
          <div className="form-control-in-div">
            <Form.Group className="mb-3">
              <Form.Label>Height </Form.Label>
              <Form.Control
                type="text"
                value={product.dimensions && product.dimensions.height}
                onChange={(e) =>
                  setProduct({
                    ...product,
                    dimensions: {
                      ...product.dimensions,
                      height: e.target.value.trimStart(),
                    },
                  })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Width </Form.Label>
              <Form.Control
                type="text"
                value={product.dimensions && product.dimensions.width}
                onChange={(e) =>
                  setProduct({
                    ...product,
                    dimensions: {
                      ...product.dimensions,
                      width: e.target.value.trimStart(),
                    },
                  })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Breadth </Form.Label>
              <Form.Control
                type="text"
                value={product.dimensions && product.dimensions.length}
                onChange={(e) =>
                  setProduct({
                    ...product,
                    dimensions: {
                      ...product.dimensions,
                      length: e.target.value.trimStart(),
                    },
                  })
                }
              />
            </Form.Group>
          </div>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Avg. Rating (Opt.)</Form.Label>
          <Form.Control
            type="number"
            value={product.average_rating || 0}
            max={5}
            min={0}
            onChange={(e) =>
              setProduct({ ...product, average_rating: e.target.value })
            }
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Rating Count (Opt.) </Form.Label>
          <Form.Control
            type="number"
            value={product.rating_count || 0}
            onChange={(e) =>
              setProduct({ ...product, rating_count: e.target.value })
            }
          />
        </Form.Group>

        <M2MRelation
          title={"Frequently Bought Together (Opt.)"}
          l_ist={product.grouped_products}
          onSelect={(e) =>
            setProduct({
              ...product,
              grouped_products: [...product.grouped_products, e],
            })
          }
          onRemove={(index) =>
            setProduct({
              ...product,
              grouped_products: removeItem(index, product.grouped_products),
            })
          }
        />

        <M2MRelation
          title={"Related Products (Opt.)"}
          l_ist={product.related_ids}
          onSelect={(e) =>
            setProduct({ ...product, related_ids: [...product.related_ids, e] })
          }
          onRemove={(index) =>
            setProduct({
              ...product,
              related_ids: removeItem(index, product.related_ids),
            })
          }
        />

        <PCategoryForm product={product} setProduct={setProduct} />

        <PTagsForm product={product} setProduct={setProduct} />

        <Form.Group className="mb-3">
          <Form.Label>Model (Opt.)</Form.Label>
          <Form.Control
            type="text"
            value={product.model || ""}
            onChange={(e) =>
              setProduct({ ...product, model: e.target.value.trimStart() })
            }
          />
        </Form.Group>

        <PBrandForm product={product} setProduct={setProduct} />

        <br />
        <h5>Meta Data</h5>

        <PMetaDataForm product={product} setProduct={setProduct} />

        <Button className="form-submit-btn" variant="primary" type="submit">
          {pk ? "Save" : "Add"}
        </Button>
      </Form>
    </AdminPage>
  );
};

export default ProductForm;
