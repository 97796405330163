import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ProductContainer from "../../components/ProductContainer";
import axios from "axios";
import { useSelector } from "react-redux";
import { basicConfigMultipart } from "../../constants";
import { placeholderProducts } from "../../CommonMethods";

const RelatedProducts = ({ product_categories, related_products }) => {
  const { categories } = useSelector((state) => state.data);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  let [productsList, setProductsList] = useState(related_products);

  // if related products is less than 5 use category to fetch like products

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchLikeProducts() {
      setLoading(true);

      if (related_products.length < 5) {
        if (product_categories && product_categories.length >= 1) {
          let foundAlike = [];
          let cList = product_categories.slice(); // making a copy
          let max_check = 0;
          // ** Limit 10 when fetching from api
          while (foundAlike.length < 2 && cList.length >= 1 && max_check < 7) {
            let formData = new FormData();

            formData.set("limit", 10);

            cList.forEach((element) => {
              formData.append("categ_ids", element.id);
            });

            await axios
              .post(
                `${process.env.REACT_APP_API_URL}/filter/`,
                formData,
                basicConfigMultipart
              )
              .catch((_) => {
                cList.pop();
              })
              .then((response) => {
                if (response && response.status === 200) {
                  foundAlike = response.data.products;
                  // if empty pop lowest categry
                  if (foundAlike.length < 2) {
                    // if 1 then its that self product
                    cList.pop();
                  } else {
                    setProductsList([...related_products, ...foundAlike]);
                  }
                } else {
                  cList.pop();
                }
              });
            max_check++;
          }
        }
      }

      setLoading(false);
    }

    fetchLikeProducts();
  }, [product_categories, categories, related_products]);

  return (
    (loading || productsList.length > 0) && (
      <>
        <Row>
          <Col sm={12} style={{ textAlign: "left" }}>
            <h3>Related Products</h3>
          </Col>
        </Row>

        <div className="related_products">
          <Row>
            <Carousel
              responsive={responsive}
              arrows={true}
              draggable={true}
              showDots={false}
              infinite={true}
              autoPlay={false}
              containerClass="carousel-container"
              itemClass="carousel-item-padding-40-px"
            >
              {loading
                ? placeholderProducts(4)
                : productsList.map((element, index) => {
                    return <ProductContainer key={index} product={element} />;
                  })}
            </Carousel>
          </Row>
        </div>
      </>
    )
  );
};

export default RelatedProducts;
