import React from "react";
import { Col, Row } from "react-bootstrap";
// import logo from "../logo.png";
import navIcon2 from "../assets/icons/nav-icon2.svg";
import navIcon3 from "../assets/icons/nav-icon3.svg";
// import navIcon4 from "../assets/icons/whatsapp-svg.svg";
import { Link } from "react-router-dom";
const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className="footer">
      <Row>
        <Col sm={12} className="map">
          <div style={{ width: "100%" }}>
            <iframe
              title="Oasis Auto Parts"
              width="100%"
              height="300"
              // frameborder="0"
              scrolling="no"
              // marginheight="0"
              // marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=300&amp;hl=en&amp;q=-1.2818291796359116,%2036.83091441357318+(Oasis%20Auto%20Parts)&amp;t=&amp;z=19&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            >
              <a href="https://www.gps.ie/">gps devices</a>
            </iframe>
          </div>
        </Col>
      </Row>
      <Row className="top_part">
        {/* <Col sm={4}>
          <img src={logo} alt="" />
          <br />
          <p>Address : 1234 Tom Mboya Str, Nation House</p>
          <p>CBD, Nairobi</p>
          <br />
          <p>Phone : (0706) 803-305</p>
          <br />
          <p>Email: mileslemi@gmail.com</p>
          <br />
          <div className="social-icon">
            <a href="/">
              <img src={navIcon4} alt="nav1" />
            </a>
            <a href="/">
              <img src={navIcon2} alt="nav2" />
            </a>
            <a href="/">
              <img src={navIcon3} alt="nav3" />
            </a>
          </div>
        </Col>
        <Col sm={1}></Col> */}
        <Col sm={3}>
          <h5>Information</h5>

          <ul>
            <li>
              <Link to={"/about-us"}>About Us</Link>
            </li>
          </ul>

          <br />

          <h5>Contact Us</h5>
          <p>
            <a href="mailto:info@oasisautoparts.co.ke">
              info@oasisautoparts.co.ke
            </a>
          </p>
          <p>(0795) 321-968</p>
          <br />
          <h5>Location</h5>
          <p>Yellow House, Kirinyaga Road, Shop 4005</p>
        </Col>
        <Col sm={3}>
          <h5>Top Categories</h5>

          <ul>
            <li>
              <Link to={"/product-category/Floor Mats/"}>Floor Mats</Link>
            </li>
            <li>
              <Link to={"/product-category/Spark Plugs/"}>Spark Plugs</Link>
            </li>
            <li>
              <Link to={"/product-category/CV Joints/"}>CV Joints</Link>
            </li>
            <li>
              <Link to={"/product-category/Oil Filters/"}>Oil Filters</Link>
            </li>
            <li>
              <Link to={"/product-category/Air Filters/"}>Air Filters</Link>
            </li>
            <li>
              <Link to={"/product-category/Brake Pads/"}>Brake Pads</Link>
            </li>
            <li>
              <Link to={"/product-category/Oil & Lubricants/"}>
                Oil & Lubricants
              </Link>
            </li>
            <li>
              <Link to={"/product-category/Seat Covers/"}>Seat Covers</Link>
            </li>
          </ul>
        </Col>
        <Col sm={3}>
          <h5>My Account</h5>

          <ul>
            <li>
              <Link to={"/profile"}>My Account</Link>
            </li>
            <li>
              <Link to={"/orders/"}>My Orders</Link>
            </li>
          </ul>
        </Col>
        <Col sm={3}>
          <h5>Social Media</h5>

          <ul className="social-icon">
            {/* <li>
              <a
                href="https://wa.me/message/6TX6YORHXUIHO1"
                target="_blank"
                rel="noreferrer"
              >
                <img src={navIcon4} alt="nav1" /> whatsapp
              </a>
            </li> */}
            <li>
              <a
                href="https://www.facebook.com/OasisAutoPartsKe"
                target="_blank"
                rel="noreferrer"
              >
                <img src={navIcon2} alt="nav2" /> Facebook
              </a>
            </li>

            <li>
              <a
                href="https://www.instagram.com/oasisautoparts_/?hl=en"
                target="_blank"
                rel="noreferrer"
              >
                <img src={navIcon3} alt="nav3" /> Instagram
              </a>
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="bottom_part">
        <div className="col-sm-12">
          <p>
            Oasis Auto Parts &copy; {year}{" "}
            <span style={{ borderLeft: "1px solid", margin: "0 5px" }}></span>{" "}
            Powered by &copy;
            <span>
              <a href="https://www.milescorp.org/">MilesCorp.</a>
            </span>
          </p>
        </div>
      </Row>
    </div>
  );
};

export default Footer;
