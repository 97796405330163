import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import Carousel from "react-multi-carousel";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { alterCart } from "../../store/cart/CartActions";

const GroupedProducts = ({ this_product, grouped_products }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const dispatch = useDispatch();

  const { shopping_cart } = useSelector((state) => state.cart);

  const [grouped_total, setGroupedTotal] = useState(0);

  useEffect(() => {
    try {
      let total_g = parseFloat(this_product?.price);
      for (const prod of grouped_products) {
        total_g += parseFloat(prod?.price);
      }
      setGroupedTotal(total_g);
    } catch (_) {}
  }, [grouped_products, this_product]);

  const onAddAllToCart = () => {
    let l = [...grouped_products, this_product];
    // check if any is in shopping_bag already
    for (const obj of l) {
      let r = (shopping_cart || []).find((x) => x.prod_id === obj?.id);
      if (!r) {
        // not in shopping cart, add
        dispatch(
          alterCart({
            prod_id: obj?.id,
            quantity: 1,
            meta_data: [],
          })
        );
      }
    }
  };

  return (
    <div className="grouped_products">
      <Row>
        <Col sm={12}>
          <h2>Frequently Bought Together</h2>
        </Col>
      </Row>
      <Row>
        <Col sm={9}>
          <Carousel
            responsive={responsive}
            arrows={true}
            draggable={true}
            showDots={false}
            autoPlay={false}
            containerClass="carousel-container"
            itemClass="carousel-item-padding-40-px"
          >
            <div className="grouped_product_container">
              <img
                src={`${process.env.REACT_APP_API_URL}\\${this_product?.images[0]?.src}`}
                alt={this_product?.name}
              />
              <h5>
                <b>This Product : </b>
                {this_product?.name}
              </h5>

              <p style={{ display: "flex" }}>
                Ksh. {this_product?.price}
                <span
                  style={{
                    color: "grey",
                    textDecoration: "line-through",
                    marginLeft: "10px",
                    fontSize: "13px",
                  }}
                >
                  Ksh. {this_product?.regular_price}
                </span>
              </p>
            </div>
            {grouped_products.map((element, index) => {
              return (
                <div className="grouped_product_container">
                  <div
                    style={{
                      position: "absolute",
                    }}
                  >
                    <Plus size={30} />
                  </div>
                  <div key={index}>
                    <Link to={`/shop/${element?.name}-${element?.id}/`}>
                      <img
                        src={`${process.env.REACT_APP_API_URL}\\${element?.images[0]?.src}`}
                        alt=""
                      />
                    </Link>
                    <Link to={`/shop/${element?.name}-${element?.id}/`}>
                      <h5>{element?.name}</h5>
                    </Link>
                    <p style={{ display: "flex" }}>
                      Ksh. {element?.price}
                      <span
                        style={{
                          color: "grey",
                          textDecoration: "line-through",
                          marginLeft: "10px",
                          fontSize: "13px",
                        }}
                      >
                        Ksh. {element?.regular_price}
                      </span>
                    </p>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </Col>
        <Col sm={3} className="grouped_products_actions">
          <h5>Total Price:</h5>
          <h4 style={{ color: "blue", fontWeight: "bold" }}>
            Ksh. {grouped_total}
          </h4>
          <Button className="add_all_to_cart" onClick={() => onAddAllToCart()}>
            ADD ALL TO CART
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default GroupedProducts;
