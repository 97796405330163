import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { FilterContext } from "../../context/FilterContext";
import { Col, Container, Row } from "react-bootstrap";
// import PriceRange from "../../components/Filter/PriceRange";
// import SearchSelectList from "../../components/Filter/SearchSelectList";
import PageProductsListing from "../../components/PageProductsListing";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";

const Catalog = () => {
  const location = useLocation();

  const { categories } = useSelector((state) => state.data);

  const urlParams = new URLSearchParams(location.search);

  const search = urlParams.get("q");

  const {
    minPrice,
    maxPrice,
    // setMinPrice,
    // setMaxPrice,
    filterbrands,
    // setBrands,
    filtercolors,
    // setColors,
  } = useContext(FilterContext);

  return (
    <>
      <Helmet>
        <title>Catalog</title>
        <meta
          name="description"
          content="Browse through Oasis Auto Parts Catalog. We've got everything for your ride."
        />
        <link rel="canonical" href="/catalog" />
      </Helmet>
      <div className="catalogPage">
        <div className="followLinks">
          <Link to={"/"}>Home </Link>/ <b>Catalog </b>
        </div>
        <Container className="custom_container">
          <Row>
            <Col sm={4} className="pageFilters">
              <h5>CATEGORY</h5>
              <div className="categLvlLinks">
                {(categories || [])
                  .filter((x) => x.top_level)
                  .map((category, index) => {
                    return (
                      <Link
                        to={`/product-category/${category.name}/?q=${search}`}
                        key={index}
                        className="topLvlCategLinks"
                      >
                        {category.name}
                      </Link>
                    );
                  })}
              </div>
              <hr />
              {/* <h5>PRICE</h5>
            <PriceRange
              minPrice={minPrice}
              maxPrice={maxPrice}
              setUpMax={setMaxPrice}
              setUpMin={setMinPrice}
            />
            <hr />
            <h5>MAKE/BRAND</h5>
            <SearchSelectList
              items={brands || []}
              keyName="name"
              setSelected={setBrands}
              selectedItems={filterbrands}
            />
            <hr />
            <h5>COLOR</h5>
            <SearchSelectList
              items={colors || []}
              keyName={"value"}
              setSelected={setColors}
              selectedItems={filtercolors}
            /> */}
            </Col>
            <Col sm={8} className="pageProductsListing">
              <PageProductsListing
                brand={filterbrands}
                color={filtercolors}
                minPrice={minPrice}
                maxPrice={maxPrice}
                search={search}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Catalog;
