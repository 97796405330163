import Carousel from "react-bootstrap/Carousel";
import React from "react";
import image10 from "../../assets/images/image10.jpg";
import floorMat from "../../assets/images/floor_mats.jpg";
import oilLube from "../../assets/images/oil_and_lube.jpg";
import sparkPlug from "../../assets/images/Spark-plug.jpg";
import CategoriesCarousel from "./CategoriesCarousel";
import { Link } from "react-router-dom";

const Banner = () => {
  const sliding_list = [
    {
      title: "Floor Mats",
      image: floorMat,
      desc: "Step up your ride! Premium floor mats and liners for a cleaner drive.",
      link: "/product-category/Floor Mats/",
    },
    {
      title: "Oil & Lubricants",
      image: oilLube,
      desc: "Keep engines purring! Premium oils and lubricants for peak performance. Shop now!",
      link: "/product-category/Oil & Lubricants/",
    },
    {
      title: "Spark Plugs",
      image: sparkPlug,
      desc: "Spark new life into your engine! Premium spark plugs for peak performance.",
      link: "/product-category/Spark Plugs/",
    },
    {
      title: "Seat Covers",
      image: image10,
      desc: "Upgrade your cockpit! From plush seats to sleek dash kits, find premium interior parts here. Drive in style!",
      link: "/product-category/Seat Covers/",
    },
  ];

  return (
    <div className="banner">
      <div className="categories_carousel">
        <CategoriesCarousel />
      </div>
      <div className="main_carousel">
        <Carousel fade indicators={false}>
          {sliding_list.map((item, index) => {
            return (
              <Carousel.Item key={index}>
                <div className="image_overlay">
                  <img src={item.image} className="d-block w-100" alt="" />
                </div>
                <Carousel.Caption>
                  <h2>{item.title}</h2>
                  <p>{item.desc}</p>
                  <Link to={item.link} className="btn">
                    Shop Now
                  </Link>
                </Carousel.Caption>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default Banner;
