import React from "react";
import { Col, Row } from "react-bootstrap";

const CheckoutCartContainer = ({ line_item }) => {
  const { name, quantity, price } = line_item;

  return (
    <Row>
      <Col style={{ width: "80%" }}>
        {name} x {quantity}
      </Col>
      <Col style={{ width: "20%" }}>Ksh. {price * quantity}</Col>
    </Row>
  );
};

export default CheckoutCartContainer;
