import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import PagePagination from "./PagePagination";
import ProductContainer from "./ProductContainer";
import axios from "axios";
import { basicConfigMultipart } from "../constants";
import { placeholderProducts } from "../CommonMethods";

const per_page_limit = 9;

const sortByList = [
  { name: "Newest Arrivals", value: "-date_created_gmt" },
  { name: "Popularity", value: "-total_sales" },
  { name: "Price : High to Low", value: "-sale_price" },
  { name: "Price : Low to High", value: "sale_price" },
];

const PageProductsListing = ({
  name,
  categList,
  brand,
  color,
  minPrice,
  maxPrice,
  search,
  stillComputing = false,
}) => {
  // this component should take name, and filters, [price, brand, color]
  // and depending on page and num of products fetch from db in here[in the useEffect setProducts, use Axio and loading ]
  // 20 products to display per page. SQL LIMIT 20

  const [loading, setLoading] = useState(true);

  // this `products` is fetched from db
  const [products, setProducts] = useState([]);

  const [productsCount, setProductsCount] = useState(0);

  const [pages, setPages] = useState(1);

  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);

  const page = urlParams.get("page") || 1;

  const [selectedSortBy, changeSorting] = useState(0);

  useEffect(() => {
    setPages(Math.ceil(productsCount / per_page_limit));
  }, [productsCount]);

  useEffect(() => {
    async function getItems() {
      setLoading(true);
      if (!stillComputing) {
        const formData = new FormData();

        if (search) {
          formData.set("q_search", search);
        }

        formData.set("min_price", minPrice);
        formData.set("max_price", maxPrice);
        formData.set("sort_by", sortByList[selectedSortBy].value);
        formData.set("limit", per_page_limit);
        formData.set("page", page);

        if (categList && categList.length >= 1) {
          categList.forEach((category) => {
            formData.append("categ_ids", category.id);
          });
        }

        if (brand && brand.length >= 1) {
          brand.forEach((element) => {
            formData.append("brands", element);
          });
        }

        if (color && color.length >= 1) {
          color.forEach((element) => {
            formData.append("meta_data", element);
          });
        }

        await axios
          .post(
            `${process.env.REACT_APP_API_URL}/filter/`,
            formData,
            basicConfigMultipart
          )
          .catch((e) => {
            // console.log(e);
          })
          .then((response) => {
            if (response && response.status === 200) {
              setProductsCount(response.data["count"]);
              setProducts(response.data["products"]);
            }
          });
      }
      setLoading(false);
    }

    getItems();
  }, [
    categList,
    minPrice,
    maxPrice,
    brand,
    color,
    selectedSortBy,
    search,
    page,
    stillComputing,
  ]);

  return (
    <div>
      <div className="titleBar">
        <h3>{name}</h3>
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            Sort By : {sortByList[selectedSortBy].name}{" "}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {sortByList.map((obj, index) => {
              return (
                <Dropdown.Item
                  key={index}
                  onClick={(e) => changeSorting(index)}
                >
                  {obj.name}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <hr></hr>
      <div className="titleBar">
        <p> {loading ? "..." : productsCount} products found</p>
      </div>
      <hr />
      {loading ? (
        <div className="pProductsListing">
          <div className="cList">{placeholderProducts(9)}</div>
        </div>
      ) : productsCount > 0 ? (
        <div className="pProductsListing">
          <div className="cList">
            {products.map((product, index) => {
              return (
                <ProductContainer
                  key={index}
                  product={product}
                  showBtn={true}
                  showPercentageOff={true}
                />
              );
            })}
          </div>
          <PagePagination page={parseInt(page) || 1} pages={pages} />
        </div>
      ) : (
        <center>No Items Found.</center>
      )}
    </div>
  );
};

export default PageProductsListing;
