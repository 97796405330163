import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>About Us</title>
        <meta
          name="description"
          content="Welcome to Oasis Auto Parts, where we Care For Your Ride."
        />
        <link rel="canonical" href="/about-us" />
      </Helmet>
      <div className="about-us">
        <Container>
          <p>
            {" "}
            <span style={{ fontSize: "24px" }}>W</span>elcome to Oasis Auto
            Parts, where we "Care For Your Ride." We are your trusted partner in
            providing top-quality car accessories and parts across Kenya. At
            Oasis, we understand that your vehicle is more than just a
            machine—it's an essential part of your journey. That's why we are
            dedicated to offering only the finest products to keep your car
            performing at its best.
          </p>

          <p>
            Our extensive range includes everything from air filters and brake
            pads to fuel pumps and high-quality lubricants, ensuring that every
            aspect of your vehicle is covered. We source our products from the
            most reputable brands, giving you peace of mind that you're getting
            reliable, durable, and high-performing parts.
          </p>

          <p>
            Whether you're looking for parts at retail or need bulk orders at
            wholesale prices, Oasis Auto Parts caters to both individual car
            owners and businesses alike. Our commitment to excellence extends to
            every customer, providing the same high level of service and
            quality, whether you're purchasing a single part or stocking up for
            your shop.
          </p>

          <p>
            At Oasis Auto Parts, our commitment to excellence goes beyond just
            supplying parts. We believe in delivering comprehensive care for
            your ride, backed by expert advice and personalized service. Whether
            you're a passionate car enthusiast, a professional mechanic, or
            simply a driver who wants the best for their vehicle, our team is
            here to support you every step of the way.{" "}
          </p>

          <p>
            Join the community of satisfied customers who trust Oasis Auto Parts
            to keep their vehicles running smoothly. Because when it comes to
            your car, we know that it's not just about getting from point A to
            point B—it's about ensuring every journey is safe, comfortable, and
            worry-free.
          </p>
          <br />
          <p>
            <b>Oasis Auto Parts: We Care For Your Ride.</b>
          </p>
        </Container>
      </div>
    </>
  );
};

export default AboutUs;
