import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ProductContainer from "../../components/ProductContainer";
import axios from "axios";
import { placeholderProducts } from "../../CommonMethods";

const limit = 6;

const PopularProducts = () => {
  const targetRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);

  const [loadMoreTrigger, setTrigger] = useState(true);

  const [popularProducts, setPopularProducts] = useState([]);

  const [noMore, setNoMore] = useState(false);

  async function fetchPopularProducts() {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/filter/?featured=1&page=${page}&limit=${limit}`
      )
      .catch((_) => {})
      .then((response) => {
        if (response && response.status === 200) {
          setPopularProducts((pre) => [...pre, ...response.data]);
          if (response.data.length < limit) {
            setNoMore(true);
          }
        }
      });
  }

  // fetch first {limit}(page 1) then on scroll, next until {limit} is not filled up
  // have a bottom component [Loading More..], on observed, load next page,

  useEffect(() => {
    async function fetch() {
      setLoading(true);
      await fetchPopularProducts();
      setLoading(false);
      setTrigger(true);
    }
    fetch();
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    const handleScroll = () => {
      if (targetRef.current) {
        const rect = targetRef.current.getBoundingClientRect();
        if (
          loadMoreTrigger &&
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <=
            (window.innerWidth || document.documentElement.clientWidth)
        ) {
          setTrigger(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loadMoreTrigger]);

  useEffect(() => {
    if (!loadMoreTrigger) {
      setPage((p) => p + 1);
    }
  }, [loadMoreTrigger]);

  return (
    <div className="popular_products">
      <Container>
        <h2>Browse Our Popular Products</h2>
        <Row>
          <Col sm={12} className="popular_content">
            {popularProducts.map((element, index) => {
              return (
                element.featured && (
                  <ProductContainer
                    key={index}
                    product={element}
                    showPercentageOff={true}
                  />
                )
              );
            })}
          </Col>
          {loading && (
            <Row>
              <Col sm={12} className="popular_content">
                {placeholderProducts(6)}
              </Col>
            </Row>
          )}
          {!noMore && (
            <Col ref={targetRef} sm={12}>
              Loading More...
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default PopularProducts;
