import React from "react";
import { Col } from "react-bootstrap";
import oasisLogo from "../../assets/images/OASIS_AUTO_PARTS_LOGO1.png";

const BrandComponent = () => {
  return (
    <Col sm={6} className="brandcomponent">
      <img src={oasisLogo} alt="tcf" />
    </Col>
  );
};

export default BrandComponent;
