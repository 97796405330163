import React from "react";
import Banner from "./Banner";
// import Categories from "./Categories";
import PopularProducts from "./PopularProducts";

const Home = () => {
  return (
    <div>
      <h1 style={{ display: "none" }}>
        Providing top-quality car accessories and parts across Kenya.
      </h1>
      <Banner />
      <PopularProducts />
      {/* <Categories /> */}
    </div>
  );
};

export default Home;
